import Model from '@itsfadnis/jsonapi-client'
import isBlank from './internal/is-blank'
import ParticipantSegment from './participant-segment'

class Hub extends Model {
  static _type = 'hubs';

  static baseURL = '/hubs';

  constructor (args = {}) {
    super(args)

    Model.call(this, args)

    this.name = args.name
    this.description = args.description
    this.state = args.state
    this.createdAt = args.createdAt
    this.updatedAt = args.updatedAt
    this.permalink = args.permalink
    this.isNewHub = args.isNewHub || false
    this.platformAnalyticsTagList = args.platformAnalyticsTagList || []
    this.userIds = args.userIds || []
    this.imageDescription = args.imageDescription
    this.imageCaption = args.imageCaption
    this.imageUrl = args.imageUrl
    this.visibilityMode = args.visibilityMode
    this.segments = this.hasMany(ParticipantSegment, args.segments)
  }

  static authorize (hubId, userId) {
    const payload = {
      data: {
        attributes: {
          user_id: userId
        }
      }
    }
    return this.adapter
      .post(`${this.constructBaseURL()}/${hubId}/admins`, payload)
  }

  static unauthorize (hubId, userId) {
    return this.adapter
      .delete(`${this.constructBaseURL()}/${hubId}/admins/${userId}`)
  }

  validate () {
    if (isBlank(this.name)) {
      this.errors.add({
        code: 'blank',
        source: {
          pointer: '/data/attributes/name'
        }
      })
    }
  }

  getTags () {
    return this.constructor.adapter
      .get(`${this.constructBaseURL()}/${this.id}/tags`)
      .then(({ data }) => this.constructor.deserialize(data))
  }

  getAdmins () {
    return this.constructor.adapter
      .get(`${this.constructBaseURL()}/${this.id}/admins`)
      .then(({ data }) => this.constructor.deserialize(data))
  }

  associateSegments () {
    return this.constructor.adapter
      .put(`${this.constructBaseURL()}/${this.id}/visibility`, this.serialize())
  }

  generatePermalink () {
    return this.constructor.adapter
      .get(`${this.constructBaseURL()}/generate_permalink?name=${this.name}`)
      .then(({ data }) => this.constructor.deserialize(data))
  }
}

export default Hub
