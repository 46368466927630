import Query from '../lib/prepare-query'
import constants from 'constants/hub-page-revisions'
import HubPageRevision from 'api/hub-page-revision'
import { setup } from 'lib/adapter'

export const fetchLastHubPageRevisions = (id) => async (dispatch, getState) => {
  const queries = {
    page: 1,
    per_page: 1
  }
  const filters = {
    sort: {
      id: 'desc'
    }
  }
  const query = new Query(queries, filters)

  dispatch({
    type: constants.FETCH_LAST_HUB_PAGE_REVISIONS_REQUEST
  })

  try {
    if (typeof window !== 'object') {
      setup(getState().currentUser.adapter)
    }

    const revisions = await HubPageRevision.query(query, {
      hubId: id
    })

    dispatch({
      type: constants.FETCH_LAST_HUB_PAGE_REVISIONS_SUCCESS,
      revision: revisions[0]
    })
  } catch (response) {
    dispatch({
      type: constants.FETCH_LAST_HUB_PAGE_REVISIONS_FAILURE,
      errorMessage: response.statusText || response.status
    })
  }
}

export const fetchLastPublishedHubPageRevisions = (id) => async (dispatch, getState) => {
  const queries = {
    page: 1,
    per_page: 1
  }
  const filters = {
    sort: {
      id: 'desc'
    },
    published: true
  }
  const query = new Query(queries, filters)

  dispatch({
    type: constants.FETCH_LAST_PUBLISHED_HUB_PAGE_REVISIONS_REQUEST
  })

  try {
    if (typeof window !== 'object') {
      setup(getState().currentUser.adapter)
    }

    const revisions = await HubPageRevision.query(query, {
      hubId: id
    })

    dispatch({
      type: constants.FETCH_LAST_PUBLISHED_HUB_PAGE_REVISIONS_SUCCESS,
      revision: revisions[0]
    })
  } catch (response) {
    dispatch({
      type: constants.FETCH_LAST_PUBLISHED_HUB_PAGE_REVISIONS_FAILURE,
      errorMessage: response.statusText || response.status
    })
  }
}
