import Model from '@itsfadnis/jsonapi-client'
import Hub from './hub'

class HubPageRevision extends Model {
  static _type = 'hub-page-revisions';

  static baseURL = '/hubs/:hubId/hub_page_revisions';

  constructBaseURL () {
    return this.constructor.constructBaseURL({
      hubId: this.hub.id
    })
  }

  constructor (args = {}) {
    super(args)

    Model.call(this, args)

    this.sections = args.sections || {}
    this.updatedAt = args.updatedAt
    this.published = typeof args.published === 'boolean' ? args.published : false

    this.hub = this.belongsTo(Hub, args.hub)
  }
}

export default HubPageRevision
