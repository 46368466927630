import constants from 'constants/hub'
import otherConstants from 'constants/hub-segments'
import Hub from 'api/hub'
import findIndex from 'lodash/findIndex'
import extractErrors from 'lib/extract-errors'

export const fetchHub = (id) => async (dispatch) => {
  dispatch({
    type: constants.FETCH_HUB_REQUEST
  })

  try {
    const hub = await Hub.fetch(id)
    dispatch({
      type: constants.FETCH_HUB_SUCCESS,
      hub
    })

    dispatch({
      type: otherConstants.SET_HUB_SEGMENTS,
      segments: hub.segments.map(s => s.id)
    })
  } catch (response) {
    dispatch({
      type: constants.FETCH_HUB_FAILURE,
      errorMessage: response.statusText || response.status
    })
  }
}

export const updateHub = (key, value) => {
  return {
    type: constants.UPDATE_HUB,
    key,
    value
  }
}

export const saveHub = () => async (dispatch, getState) => {
  dispatch({
    type: constants.SAVE_HUB_REQUEST
  })

  const hub = new Hub(getState().hub.hub)

  try {
    const response = await hub.save()
    dispatch({
      type: constants.SAVE_HUB_SUCCESS,
      hub: response
    })
  } catch (err) {
    dispatch({
      type: constants.SAVE_HUB_FAILURE,
      errorMessage: err.message || err.statusText || err.status,
      errors: hub.errors.extract()
    })
  }
}

export const generatePermalink = name => async (dispatch, getState) => {
  dispatch({
    type: constants.FETCH_HUB_PERMALINK_REQUEST
  })

  const hub = new Hub({
    name,
    hub: getState().hub.hub
  })

  try {
    const response = await hub.generatePermalink()

    dispatch({
      type: constants.FETCH_HUB_PERMALINK_SUCCESS,
      permalink: response.permalink
    })
  } catch (exception) {
    const errorMessage = extractErrors(exception, 'permalink')
    dispatch({
      type: constants.FETCH_HUB_PERMALINK_FAILURE,
      errorMessage: errorMessage,
      errors: hub.errors.extract()
    })
  }
}

export const resetHub = () => {
  return {
    type: constants.RESET_HUB
  }
}

export const deleteHub = (id) => async (dispatch, getState) => {
  dispatch({
    type: constants.DELETE_HUB_REQUEST,
    id
  })

  try {
    await Hub.destroy(id, {
      hubId: getState().hub.hub.id
    })

    dispatch({
      type: constants.DELETE_HUB_SUCCESS,
      id
    })
  } catch (response) {
    dispatch({
      type: constants.DELETE_HUB_FAILURE,
      id,
      errorMessage: response.statusText || response.status
    })
  }
}

export const associatedHubAdmins = (id) => async (dispatch, getState) => {
  dispatch({
    type: constants.FETCH_HUB_ADMIN_REQUEST
  })

  const hub = new Hub({
    id,
    hub: getState().hub.hub
  })

  try {
    const response = await hub.getAdmins()
    dispatch({
      type: constants.FETCH_HUB_ADMIN_SUCCESS,
      hub: response.map(s => s.id)
    })
  } catch (response) {
    dispatch({
      type: constants.FETCH_HUB_ADMIN_FAILURE,
      errorMessage: response.statusText || response.status
    })
  }
}

export const authorizeHubAdmin = (hubId, userId) => async (dispatch, getState) => {
  dispatch({
    type: constants.AUTHORIZE_HUB_ADMIN_REQUEST
  })
  try {
    await Hub.authorize(hubId, userId)
    const data = getState().hub.hub.userIds
    dispatch({
      type: constants.AUTHORIZE_HUB_ADMIN_SUCCESS,
      hub: {
        ...getState().hub.hub.userIds,
        userIds: data.concat(userId)
      }
    })
  } catch (response) {
    dispatch({
      type: constants.AUTHORIZE_HUB_ADMIN_FAILURE,
      errorMessage: response.statusText || response.status
    })
  }
}

export const unauthorizeHubAdmin = (hubId, userId) => async (dispatch, getState) => {
  dispatch({
    type: constants.UNAUTHORIZE_HUB_ADMIN_REQUEST
  })
  const index = findIndex(getState().hub.hub.userIds, user => user === userId)
  try {
    await Hub.unauthorize(hubId, userId)
    dispatch({
      type: constants.UNAUTHORIZE_HUB_ADMIN_SUCCESS,
      id: index
    })
  } catch (response) {
    dispatch({
      type: constants.UNAUTHORIZE_HUB_ADMIN_FAILURE,
      errorMessage: response.statusText || response.status
    })
  }
}
