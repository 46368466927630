/**
 * Extract server errors from failed requests
 * @TODO integrate into jsonapi lib
 * @returns string
 */
const extractErrors = ({ data }, key) => {
  return data?.errors[key]
}

export default extractErrors
